import React, { useContext, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AppContext } from '../AppContext';
import Logo from './Logo';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import SocialMediaLinks from './SocialMediaLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Importujemy LoginPopup oraz Login
import Popup from './Popup';
import AuthPopup from './AuthPopup';


const Navbar = () => {
  const { translations, settings, languages, setLanguage } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPlansDropdown, setShowPlansDropdown] = useState(false);
  const [showAuthPopup, setShowAuthPopup] = useState(false); // Dodany stan dla logowania w popupie

  const plansDropdownRef = useRef(null);

  // Sprawdzenie, czy token istnieje w ciasteczkach
  const token = Cookies.get('token');
  
  // Aktualnie wybrany język
  const currentLanguage = Cookies.get('language') || settings.defaultLanguage || "en";

  // Posortowanie języków tak, aby aktualny był pierwszy
  const sortedLanguages = [
    ...languages.filter((lang) => lang.languageCode === currentLanguage),
    ...languages.filter((lang) => lang.languageCode !== currentLanguage)
  ];

  // Funkcja obsługi zmiany języka i zapisania w cookies
  const handleLanguageChange = (selectedLanguage) => {
    if (selectedLanguage) {
      Cookies.set('language', selectedLanguage);
      setLanguage(selectedLanguage);
      setShowDropdown(false); // Zamknięcie listboxa po wyborze języka
      window.location.reload();
    }
  };

// Funkcja do obsługi wylogowania
const handleLogout = async () => {
  try {
    // Wyślij żądanie wylogowania do serwera
    const response = await axios.post('/api/auth/logout');

    if (response.status === 200) {
      // Usuń token i preferencje językowe z ciasteczek
      Cookies.remove('token');

      // Przekieruj na stronę logowania
      navigate('/');
    }
  } catch (error) {
    console.error('Błąd podczas wylogowywania:', error);
  }
};

  // Function to determine if a link is active based on the current URL
  const isActive = (path) => {
    return location.pathname === path ? 'active-link' : '';
  };

  // Funkcja obsługująca kliknięcie w zakładkę "Plany"
  const handlePlansClick = (event) => {
    event.stopPropagation();
    setShowPlansDropdown((prev) => !prev);
  };

// useEffect do zamknięcia dropdownu po kliknięciu poza nim
useEffect(() => {
  const handleClickOutside = (event) => {
    if (plansDropdownRef.current && !plansDropdownRef.current.contains(event.target) && !event.target.closest('.nav-links li')) {
      setShowPlansDropdown(false);
    }
  };

  if (showPlansDropdown) {
    document.addEventListener('mousedown', handleClickOutside);
  } else {
    document.removeEventListener('mousedown', handleClickOutside);
  }

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [showPlansDropdown]);


  return (
    <nav className="navbar">
      <div className="container">
        {/* Logo aplikacji */}
        <Logo 
          logoUrl={settings.iconlogoUrl ? settings.iconlogoUrl : settings.logoUrl} 
          isIcon={!!settings.iconlogoUrl}
        />

        {/* Linki nawigacyjne */}
        <div className="main-navigation">
          <ul className="nav-links">
            <li className={isActive('/')} onClick={handlePlansClick}>
              <a>
                <div>
                  <span className="title">Plany</span>
                  <span className="description"> Dieta i Trening <FontAwesomeIcon icon={['fas', 'caret-down']} className={`dropdown-arrow ${showPlansDropdown ? 'rotate-90' : ''}`}/></span>
                </div>
              </a>
            </li>
            <li className={isActive('/aboutus')}>
              <a href="/aboutus">
                <div>
                  <span className="title">O Nas</span>
                  <span className="description">Nasza Misja</span>
                </div>
              </a>          
            </li>
            <li className={isActive('/calculator')}>
              <a href="/calculator">
                <div>
                  <span className="title">Kalkulator</span>
                  <span className="description">Zapotrzebowania Kalorycznego</span>
                </div>
              </a>          
            </li>
            <li className={isActive('/contact')}>
              <a href="/contact">
                <div>
                  <span className="title">Kontakt</span>
                  <span className="description">Napisz do nas</span>
                </div>
              </a>          
            </li>
            {/* Możesz dodać więcej elementów nawigacyjnych tutaj */}
          </ul>
        </div>

        {/* Dodajemy SocialMediaLinks do nawigacji */}
        <SocialMediaLinks links={settings.socialMediaLinks} />

        <div className="nav-diet-link">
          <span className="badge dark">{translations["badges_check_diet"]}</span>
          <a href="#">Kup Diete lub Trening</a>
        </div>

        <div className="nav-profile">
          <FontAwesomeIcon
            icon={['far', 'user']}
            onClick={() => {
              if (token) {
                navigate('/account'); // Przekierowanie na stronę konta, jeśli użytkownik jest zalogowany
              } else {
                setShowAuthPopup(true); // Pokazujemy AuthPopup, jeśli użytkownik nie jest zalogowany
              }
            }}
          />
        </div>    

      {token && (
    <button
      onClick={handleLogout}
      className="logout-button"
      style={{ marginLeft: '10px', cursor: 'pointer' }}
    >
      {translations['logout'] || 'Logout'}
    </button>
  )}

        {/* Wybór języka */}
        <div className="language-selector" onClick={() => setShowDropdown(!showDropdown)}>
          <img
            src={
              languages.find(lang => lang.languageCode === currentLanguage)?.languageIcon
            }
            width="30"
            className="language-icon"
          />
          <span className="language-code">
            {currentLanguage.toUpperCase()}
          </span>
          <FontAwesomeIcon
            icon="chevron-down"
            className={`dropdown-arrow ${showDropdown ? 'rotate' : ''}`}
          />

          {showDropdown && (
            <div className="language-dropdown">
              {sortedLanguages.map((lang) => (
                <div
                  key={lang.languageCode}
                  className={`language-option ${
                    lang.languageCode === currentLanguage ? 'current-language' : ''
                  }`}
                  onClick={() => handleLanguageChange(lang.languageCode)}
                >
                  <img
                    src={lang.languageIcon}
                    alt={translations[lang.languageKey] || lang.languageCode}
                    width="20"
                    className="language-option-icon"
                  />
                  <span>{translations[lang.languageKey] || lang.languageCode}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <div ref={plansDropdownRef} className={`subnav-plans-dropdown ${showPlansDropdown ? 'slide-down' : ''}`}>
          <ul>
            <li>
              <div className="plan-item diet">
                <a className="title">Indywidualny Plan</a>
                <a className="description">Dietetyczny</a>
                <a className="description2">+ Opieka Dietetyka</a>
              </div>  
            </li>
            <li>
              <div className="plan-item trening">
                <a className="title">Indywidualny Plan</a>
                <a className="description">Treningowy</a>
                <a className="description2">+ Opieka Dietetyka</a>
              </div>  
            </li>
            <li>
              <div className="plan-item dietandtrening">
                <a className="title">Indywidualny Plan</a>
                <a className="description">Trening + Dieta</a>
                <a className="description2">+ Opieka Trenera Dietetyka</a>
              </div>  
            </li>
          </ul>
        </div>
      </div>
       {/* Pop-up logowania/rejestracji */}
       {showAuthPopup && (
        <Popup onClose={() => setShowAuthPopup(false)}>
          <AuthPopup /> 
        </Popup>
      )}
    </nav>
  );
};

export default Navbar;