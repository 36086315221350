import React, { createContext, useState, useEffect } from 'react';
import axios from './utils/axiosInstance';  // Importujemy naszą skonfigurowaną instancję Axiosa
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

// Tworzenie kontekstu dla aplikacji
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [settings, setSettings] = useState({});
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(Cookies.get('language') || 'en'); // Initialize language from cookies or default to 'en'

  // Fetch settings from the backend, including the default language
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/settings');
        setSettings(response.data);

        // If no language is stored in cookies, set the default language from settings
        if (!Cookies.get('language')) {
          const defaultLang = response.data.defaultLanguage || 'en';
          setLanguage(defaultLang);
          Cookies.set('language', defaultLang);  // Save default language to cookies
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchSettings();
  }, []);

  // Fetch translations based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axios.get(`/api/translations?lang=${language}`);
        setTranslations(response.data);  // Load translations based on the selected language
      } catch (error) {
        console.error("Error fetching translations:", error);
      }
    };
    fetchTranslations();
  }, [language]);  // Re-fetch translations when the language changes

  // Fetch the list of available languages from the backend
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get('/api/languages');
        setLanguages(response.data);  // Load available languages from the database
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  // Function to change the current language and update cookies
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    Cookies.set('language', newLanguage);
    window.location.reload();  // Reload the page to apply the new language
  };

  return (
    <AppContext.Provider value={{ translations, settings, languages, language, setLanguage: changeLanguage }}>
      <Helmet>
        {/* Dynamically set the page title based on translations */}
        <title>{translations[settings.siteNameKey]}</title>  
        {/* Set the favicon */}
        <link rel="icon" href={settings.faviconUrl || "/favicon.ico"} />  
      </Helmet>
      {children}
    </AppContext.Provider>
  );
};