import React, { useState, useContext } from 'react';
import { AppContext } from '../AppContext';
import Login from './Login';
import Register from './Register';

const AuthPopup = () => {

  const { translations } = useContext(AppContext);  // Kontekst do tłumaczeń

  const [isLoginView, setIsLoginView] = useState(true); // Dodajemy stan dla zmiany widoku

  return (

    <div className="auth-popup">
      <div className="auth-popup-container">
        <div className="auth-popup-image">
        </div>
        <div className="auth-popup-form">
          {isLoginView ? (
            <>
              <Login />
              
              <div className="auth-popup-signup">
                <a className="auth-popup-signup-text">{translations['authform_signup_title']}</a>

                <div className="auth-popup-signup-button">
                  <a href="#" onClick={(e) => { e.preventDefault(); setIsLoginView(false) }}>
                    {translations['authform_signup_button']}
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <Register /> {/* Komponent rejestracji */}

              <p className="toggle-view-text">
                {`Masz już konto? `}
                <button onClick={() => setIsLoginView(true)} className="toggle-view-btn">
                  {`Zaloguj się`}
                </button>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthPopup;
