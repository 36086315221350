import React, { useContext } from 'react';
import { AppContext } from '../AppContext';


import Navbar from '../components/Navbar';
import BMRCalculator from '../components/BMRCalculator';
import Footer from '../components/Footer';

const HomePage = () => {
  const { translations } = useContext(AppContext);

  return (
    <div className="homepage">
      <Navbar />
      <div className="container">
          <main>
          </main>
      </div>
      <Footer translations={translations} />
</div>
  );
};

export default HomePage;
