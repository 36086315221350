import React, { useContext } from 'react';
import { AppContext } from '../AppContext';


import Navbar from '../components/Navbar';
import BMRCalculator from '../components/BMRCalculator';
import Footer from '../components/Footer';

const HomePage = () => {
  const { translations } = useContext(AppContext);

  return (
    <div className="homepage">
      <Navbar />
      <div className="container">
          <main>

            <div className="calorie-calculator">
              <div className="header">
                {translations["calculator_header"]}
              </div>
              <div className="calculator">
                <BMRCalculator translations={translations} />
              </div>
            </div>  
          </main>
      </div>
      <Footer translations={translations} />
</div>
  );
};

export default HomePage;
