import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppProvider } from './AppContext';
import { NotificationProvider } from './context/NotificationContext';


import useButtonCooldown from './hooks/buttonCooldown';

import HomePage from './pages/HomePage';
import Login from './pages/Login';
import Register from './pages/Register';
import Calculator from './pages/Calculator';

import Popup from './components/Popup';

const App = () => {
    useButtonCooldown();
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const openPopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupContent(null);
  };

  return (
    <NotificationProvider>
      <AppProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage openPopup={openPopup} />} />
            <Route path="/register" element={<Register openPopup={openPopup} />} />
            <Route path="/login" element={<Login openPopup={openPopup} />} />
            <Route path="/calculator" element={<Calculator openPopup={openPopup} />} />
          </Routes>
        </Router>
        {/* Globalny Popup */}
        {showPopup && (
          <Popup onClose={closePopup}>
            {popupContent}
          </Popup>
        )}
      </AppProvider>
    </NotificationProvider>
  );
};

export default App;
