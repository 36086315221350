import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const { translations } = useContext(AppContext);  // Kontekst do tłumaczeń
  const [email, setEmail] = useState('');  // Stan do przechowywania e-maila
  const [password, setPassword] = useState('');  // Stan do przechowywania hasła
  const [name, setName] = useState('');  // Stan do przechowywania imienia
  const [age, setAge] = useState('');  // Stan do przechowywania wieku
  const [height, setHeight] = useState('');  // Stan do przechowywania wzrostu
  const [error, setError] = useState('');  // Stan do przechowywania błędów
  const navigate = useNavigate();  // Używamy useNavigate do nawigacji po rejestracji

  // Sprawdzamy, czy użytkownik jest już zalogowany
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/');  // Przekierowanie na stronę główną, jeśli użytkownik już jest zalogowany
    }
  }, [navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();  // Zapobiegamy odświeżeniu strony
    try {
      // Pobieramy preferowany język z cookies, domyślnie 'en'
      const preferredLanguage = Cookies.get('language') || 'en';

      // Wysyłamy dane użytkownika do API
      const response = await axios.post('/api/auth/register', {
        email,
        password,
        name,
        age,
        height,
        language: preferredLanguage,  // Przekazujemy preferowany język
      });

      // Jeśli rejestracja się powiedzie
      if (response.status === 201) {
        alert(translations['registration_success']);  // Wyświetlamy komunikat sukcesu
        navigate('/login');  // Przekierowujemy na stronę logowania
      }
    } catch (err) {
      // Ustawiamy komunikat błędu w razie problemu
      setError(translations['registration_failed'] || 'Registration failed.');
    }
  };

  return (
    <div className="register-page">
      <h2>{translations['register']}</h2>
      <form onSubmit={handleRegister}>
        <div className="form-group">
          <label>{translations['email']}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>{translations['password']}</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>{translations['name']}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>{translations['age']}</label>
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>{translations['height']}</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>

        {/* Wyświetlamy komunikat błędu jeśli istnieje */}
        {error && <p className="error-message">{error}</p>}

        <button type="submit" className="register-btn">
          {translations['register']}
        </button>
      </form>
    </div>
  );
};

export default Register;
