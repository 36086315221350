import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialMediaLinks = ({ links = {} }) => (
  <div className="social-media-links">
    {links.facebook && (
      <a href={links.facebook} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
    )}
    {links.instagram && (
      <a href={links.instagram} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
    )}
    {links.tiktok && (
      <a href={links.tiktok} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'tiktok']} /></a>
    )}
    {links.youtube && (
      <a href={links.youtube} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} /></a>
    )}
  </div>
);

export default SocialMediaLinks;
