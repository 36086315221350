// src/utils/axiosInstance.js

import axios from 'axios';

// Tworzenie instancji Axiosa z niestandardową konfiguracją
const instance = axios.create({
  baseURL: 'https://shapzy.com/api',  // URL API backendu na HTTP
  validateStatus: function (status) {
    return status >= 200 && status < 400; // Tylko odpowiedzi z zakresem 200-399 są traktowane jako prawidłowe
  }
});

// W przypadku braku bezpośredniego ustawienia ignorowania certyfikatu:
// W środowisku HTTP certyfikaty nie są wymagane, więc nie musimy martwić się ich ignorowaniem.

export default instance;
