import React, { useState } from 'react';
import { useNotification } from '../context/NotificationContext';
import Popup from './Popup';  // Importujemy Popup

const BMRCalculator = ({ translations }) => {
  const [gender, setGender] = useState('female');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [workoutActivity, setWorkoutActivity] = useState('0');
  const [normalActivity, setNormalActivity] = useState('0');
  const [bmr, setBmr] = useState(null);
  const [basicBMR, setBasicBMR] = useState(null); // Dodajemy stan dla podstawowego BMR

  const [inputWarnings, setInputWarnings] = useState({});
  const [showPopup, setShowPopup] = useState(false); // Dodano stan do zarządzania wyświetlaniem popupu

  const { addNotification } = useNotification();

  const isFormValid = () => {
    return weight && height && age && workoutActivity !== '0' && normalActivity !== '0';
  };

  const validateForm = (formData, translations) => {
    const { weight, height, age, workoutActivity, normalActivity } = formData;
    const newInputWarnings = {};

    // Dodajemy błędy do inputWarnings
    if (!weight) newInputWarnings['weight'] = true;
    if (!height) newInputWarnings['height'] = true;
    if (!age) newInputWarnings['age'] = true;
    if (workoutActivity === '0') newInputWarnings['workoutActivity'] = true;
    if (normalActivity === '0') newInputWarnings['normalActivity'] = true;

    // Ustawiamy błędy w stanie
    setInputWarnings(newInputWarnings);

    // Jeśli są błędy, wysyłamy jedno powiadomienie
    if (Object.keys(newInputWarnings).length > 0) {
      addNotification('warning', translations['notification_warning_check_inputs_title'], translations['notification_warning_check_inputs_message'], 5000);
      return false;
    }

    return true; // Jeśli nie ma błędów, formularz jest prawidłowy
  };

  const calculateBMR = () => {
    const formData = { weight, height, age, workoutActivity, normalActivity };
  
    // Używamy funkcji walidacji
    if (!validateForm(formData, translations)) {
      return;
    }
  
    let calculatedBasicBMR;
  
    // Oblicz podstawowe BMR bez uwzględnienia aktywności
    if (gender === 'male') {
      calculatedBasicBMR = 10 * weight + 6.25 * height - 5 * age + 5;
    } else {
      calculatedBasicBMR = 10 * weight + 6.25 * height - 5 * age - 161;
    }
  
    setBasicBMR(calculatedBasicBMR.toFixed(0)); // Zapisujemy podstawowe BMR do stanu
  
    // Definiujemy mnożniki dla aktywności treningowej
    const workoutMultipliers = {
      1: 1.2,    // Brak treningów
      2: 1.375,  // 2-3 treningi w tygodniu
      3: 1.55,   // 4-5 treningów w tygodniu
      4: 1.725,  // 6 treningów w tygodniu
      5: 1.9     // Codzienne treningi, zawodowi sportowcy
    };
  
    // Definiujemy mnożniki dla aktywności poza treningiem
    const normalActivityMultipliers = {
      1: 1.2,   // Praca siedząca
      2: 1.4,   // Praca siedząca z małą aktywnością
      3: 1.6,   // Praca umiarkowana
      4: 1.8    // Ciężka praca fizyczna
    };
  
    // Średni mnożnik aktywności (uwzględniający zarówno aktywność treningową, jak i poza treningiem)
    let activityMultiplier = 1.2; // Domyślnie zakładamy brak aktywności
  
    if (workoutActivity && workoutActivity !== '0' && normalActivity && normalActivity !== '0') {
      // Obliczamy średni mnożnik z aktywności treningowej i poza treningiem
      activityMultiplier = (workoutMultipliers[workoutActivity] + normalActivityMultipliers[normalActivity]) / 2;
    } else if (workoutActivity && workoutActivity !== '0') {
      // Używamy tylko mnożnika aktywności treningowej, jeśli brak aktywności poza treningiem
      activityMultiplier = workoutMultipliers[workoutActivity];
    } else if (normalActivity && normalActivity !== '0') {
      // Używamy tylko mnożnika aktywności poza treningiem, jeśli brak aktywności treningowej
      activityMultiplier = normalActivityMultipliers[normalActivity];
    }
  
    // Obliczamy CPM (całkowite zapotrzebowanie kaloryczne)
    let calculatedBMR = calculatedBasicBMR * activityMultiplier;
  
    // Zapisujemy wynik obliczeń i pokazujemy w popupie
    setBmr(calculatedBMR.toFixed(0));
    setShowPopup(true); // Ustawiamy, aby wyświetlić popup
  };


  return (
    <div className="calculator-form">
      {/* Wybór płci */}
      <div className="gender-selection">
        <a
          href="#"
          className={gender === 'male' ? 'selected' : ''}
          onClick={(e) => { e.preventDefault(); setGender('male'); }}
        >
          {gender === 'male' && <span className="badge red">{translations["badges_selected"]}</span>}
          {translations["calculator_gender_male"]}
        </a>
        <a
          href="#"
          className={gender === 'female' ? 'selected' : ''}
          onClick={(e) => { e.preventDefault(); setGender('female'); }}
        >
          {gender === 'female' && <span className="badge red">{translations["badges_selected"]}</span>}
          {translations["calculator_gender_female"]}
        </a>
      </div>

      {/* Pola input dla wagi, wzrostu, wieku */}

      <div className="input-group">
        <label>{translations["calculator_age_label"]}</label>
        <input
          type="number"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          placeholder={translations["calculator_age_placeholder"]}
          inputMode="numeric"
          className={`no-spinner ${inputWarnings.age ? 'input-warning' : ''}`}
        />
        <span>{translations["calculator_age_unit"]}</span>
      </div>

      <div className="input-group">
        <label>{translations["calculator_weight_label"]}</label>
        <input
          type="number"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          placeholder={translations["calculator_weight_placeholder"]}
          inputMode="numeric"
          className={`no-spinner ${inputWarnings.weight ? 'input-warning' : ''}`}
        />
        <span>{translations["calculator_weight_unit"]}</span>
      </div>

      <div className="input-group">
        <label>{translations["calculator_height_label"]}</label>
        <input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          placeholder={translations["calculator_height_placeholder"]}
          inputMode="numeric"
          className={`no-spinner ${inputWarnings.height ? 'input-warning' : ''}`}
        />
        <span>{translations["calculator_height_unit"]}</span>
      </div>

      <div className="input-group">
        <select
          value={workoutActivity}
          onChange={(e) => setWorkoutActivity(e.target.value)}
          className={`${inputWarnings.workoutActivity ? 'input-warning' : ''}`}
        >
          <option value="0">Aktywność treningowa</option>
          <option value="1">Nie trenuje</option>
          <option value="2">2-3 treningi w tygodniu</option>
          <option value="3">4-5 treningów w tygodniu</option>
          <option value="4">6 treningów w tygodniu (bardzo intensywne)</option>
          <option value="5">Codzienne treningi (zawodowi sportowcy)</option>
        </select>
      </div>

      <div className="input-group">
        <select
          value={normalActivity}
          onChange={(e) => setNormalActivity(e.target.value)}
          className={`${inputWarnings.normalActivity ? 'input-warning' : ''}`}
        >
          <option value="0">Aktywność pozatreningowa</option>
          <option value="1">Praca siedząca - około 5 tys kroków</option>
          <option value="2">Praca siedząca z dodatkiem małej aktywności fizycznej - około 10 tys kroków</option>
          <option value="3">Praca umiarkowana -  około 15 tys kroków</option>
          <option value="4">Ciężka praca fizyczna, np. na budowie/kopalni – ciągle przenoszenie ciężkich rzeczy</option>
        </select>
      </div>

      {/* Przycisk obliczania */}
      
      <div className="button-wrapper">
        <span className="calculate-button-badge">
          {isFormValid() && <span className="badge red badge-calculator">{translations["badges_calculate"]}</span>}
          <a href="#" onClick={(e) => { e.preventDefault(); calculateBMR(); }} className="button button-cooldown">
            {translations["calculator_bmr_button"]}
          </a>
        </span>
      </div>

      {/* Wyświetlanie popupu z wynikiem BMR */}
      {bmr && showPopup && (
        <Popup onClose={() => setShowPopup(false)}>
          <div className="bmr-popup-content">
            <h2>{translations["bmr_result_title"] || "Twoje BMR wynosi:"}</h2>
            <p>{`Podstawowa przemiana materii (PPM): ${basicBMR} kcal/dzień`}</p>
            <p>{`Całkowita przemiana materii (CPM): ${bmr} kcal/dzień`}</p>
            <p>{translations["macro_result_title"] || "Rozkład makroskładników:"}</p>
            <ul>
              <li>{`Białko: ${(bmr * 0.25 / 4).toFixed(2)} g`}</li>
              <li>{`Tłuszcze: ${(bmr * 0.30 / 9).toFixed(2)} g`}</li>
              <li>{`Węglowodany: ${(bmr * 0.45 / 4).toFixed(2)} g`}</li>
            </ul>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default BMRCalculator;
