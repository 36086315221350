import React, { useEffect, useState, useRef } from 'react';
import FontAwesomeIcon from './FontAwesomeIcons'; // Importujemy komponent FontAwesomeIcon

const NotificationItem = ({ notification }) => {
  const [progress, setProgress] = useState(100);
  const { duration, type, title, message } = notification;
  const startTimeRef = useRef(Date.now());
  const [remainingTime, setRemainingTime] = useState(duration);

  // Przekazujemy nazwę ikony w zależności od typu powiadomienia
  const icon = type === 'error' ? ['far', 'circle-xmark'] : type === 'success' ? ['fas', 'check'] : type === 'warning' ? ['fa', 'triangle-exclamation'] : null;

  useEffect(() => {
    const updateProgress = () => {
      const elapsedTime = Date.now() - startTimeRef.current;
      const remaining = Math.max(duration - elapsedTime, 0);
      setRemainingTime(remaining);
      const newProgress = (remaining / duration) * 100;
      setProgress(newProgress);
    };

    const intervalId = setInterval(updateProgress, 100);

    const timeoutId = setTimeout(() => {
      notification.remove(notification.id);
    }, duration);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [notification, duration]);

  return (
    <div className={`notification ${type}`}>
      <div className="icon">
        {icon && <FontAwesomeIcon icon={icon} className={`notification-icon ${type}`} />} {/* Użycie nazw ikon */}
      </div>
      <div className="output">
        <a className="title">{title}</a>
        <a className="message">{message}</a>
      </div>
      <button onClick={() => notification.remove(notification.id)}><FontAwesomeIcon icon="fa-xmark"/></button>
      <div className="notification-progress-bg">
        <div
          className="notification-progress"
          style={{
            width: `${progress}%`,
            transition: 'width 100ms linear',
          }}
        ></div>
      </div>
    </div>
  );
};

export default NotificationItem;
