import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../context/NotificationContext';
import useButtonCooldown from '../hooks/buttonCooldown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const Login = () => {
  useButtonCooldown();

  const { translations } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [warning, setWarning] = useState('');
  const [inputWarnings, setInputWarnings] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const navigate = useNavigate();

  const { addNotification } = useNotification();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const validateForm = () => {
    const newInputWarnings = {};
    if (!email) newInputWarnings['email'] = true;
    if (!password) newInputWarnings['password'] = true;

    setInputWarnings(newInputWarnings);

    if (Object.keys(newInputWarnings).length > 0) {
      addNotification('warning', translations['notification_warning_check_inputs_title'], translations['notification_warning_check_inputs_message'], 5000);
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post('/api/auth/login', {
        email,
        password,
      });

      if (response.status === 200) {
        addNotification('success', translations['notification_success_logged_title'], translations['notification_success_logged_message'], 5000);
        setTimeout(() => {
      
          navigate('/account');
        }, 4000);
      }
    } catch (err) {
      if (err.response && err.response.status === 401 && err.response.data.error === 'Please verify your email before logging in.') {
        addNotification('error', translations['notification_warning_account_not_activated_title'], translations['notification_warning_account_not_activated_message'], 5000);
      } else {
        addNotification('error', translations['notification_warning_invalid_email_password_title'], translations['notification_warning_invalid_email_password_message'], 5000);
        setInputErrors({ email: true, password: true });
      }
    }
  };

  return (
    <div className="login-popup-form">
      <h2 className="title">{translations['loginform_title']}</h2>

      <label>{translations['loginform_email']}</label>
      <div className="input-group">
        <FontAwesomeIcon className="input-icon" icon={faAt} />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={translations['loginform_placeholder_email']}
          className={`${inputWarnings.email ? 'input-warning' : ''} ${inputErrors.email ? 'input-error' : ''}`}
          required
        />
      </div>

      <label>{translations['loginform_password']}</label>
      <div className="input-group">
        <FontAwesomeIcon className="input-icon" icon={faFingerprint} />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={translations['loginform_placeholder_password']}
          className={`${inputWarnings.password ? 'input-warning' : ''} ${inputErrors.password ? 'input-error' : ''}`}
          required
        />
      </div>

      <a className="forgotpassword">{translations['loginform_forgetpassword']}</a>

      {warning && <p className="warning-message">{warning}</p>}

      <div className="button-wrapper">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleLogin(e);
          }}
          className="button button-cooldown"
        >
          {translations["loginform_login"]}
        </a>
      </div>

      <div className="signin-socialmedia">
        <div className="signin-facebook">
          <FacebookLogin
            appId="596278462932408"
            autoLoad={false}
            fields="name,email,picture"
            render={(renderProps) => (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  renderProps.onClick();
                }}
                style={{
                  textDecoration: 'none',
                  color: '#1877f2',
                  cursor: 'pointer',
                  display: 'inline-block',
                  fontSize: '16px',
                }}
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;