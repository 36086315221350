import React from 'react';

const Footer = ({ translations }) => (
  <footer className="footer">
    <p>&copy; 2024 {translations["app_name"]}. {translations["all_rights_reserved"]}</p>
    <ul>
      <li>{translations["privacy_policy"]}</li>
      <li>{translations["terms_of_service"]}</li>
      <li>{translations["contact"]}</li>
    </ul>
  </footer>
);

export default Footer;
