import React, { createContext, useContext, useState, useCallback } from 'react';
import NotificationSystem from '../components/NotificationSystem'; // Upewnij się, że ścieżka jest poprawna

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // Funkcja usuwania powiadomienia
  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  // Dodaj powiadomienie
  const addNotification = useCallback((type, title, message, duration = 5000) => {
    const id = new Date().getTime();
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      { id, type, title, message, duration, remove: removeNotification },
    ]);

    // Automatyczne usunięcie powiadomienia po czasie `duration`
    setTimeout(() => {
      removeNotification(id);
    }, duration);
  }, [removeNotification]); // Dodaj `removeNotification` do listy zależności

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}
      <NotificationSystem notifications={notifications} />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
