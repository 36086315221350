import React from 'react';
import NotificationItem from './NotificationItem'; // Importujemy NotificationItem

const NotificationSystem = ({ notifications }) => {
  return (
    <div className="notifications-container">
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification} // Przekazujemy cały obiekt `notification`
        />
      ))}
    </div>
  );
};

export default NotificationSystem;
