import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Login from '../components/Login';

const LoginPage = () => {
  const { translations } = useContext(AppContext);

  return (
    <div>
      <Navbar />
      <main>
        <Login translations={translations} />
      </main>
      <Footer translations={translations} />
    </div>
  );
};

export default LoginPage;
