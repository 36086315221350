import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Register from '../components/Register';

const RegisterPage = () => {
  const { translations } = useContext(AppContext);

  return (
    <div>
      <Navbar />
      <main>
        <Register translations={translations} />
      </main>
      <Footer translations={translations} />
    </div>
  );
};

export default RegisterPage;
