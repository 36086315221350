// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';

import { FontAwesomeIcons } from './components/FontAwesomeIcons';

// Importujemy globalne style
import './css/styles.css';
import './css/navbar.css';
import './css/bmrcalculator.css';
import './css/notification.css';
import './css/badges.css';
import './css/forms.css';
import './css/popup.css';
import './css/login.css';

// Dodajemy ikony do biblioteki globalnej Font Awesome

// Render aplikacji z AppProvider i NotificationProvider
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
