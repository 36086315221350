// Popup.js - Komponent Popup
import React from 'react';

const Popup = ({ onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target.className === 'popup-overlay') {
      onClose();
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>&times;</button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
