// buttonCooldown.js - Skrypt dodający cooldown do wszystkich przycisków na stronie z React

import { useEffect } from 'react';

const useButtonCooldown = () => {
  useEffect(() => {
    // Funkcja dodająca cooldown do przycisków
    const addCooldownToButtons = () => {
      const buttons = document.querySelectorAll('button, a.button-cooldown'); // Wybierz wszystkie przyciski oraz linki, które działają jak przyciski

      buttons.forEach((button) => {
        // Sprawdź, czy listener nie został już dodany
        if (button.dataset.cooldownListener !== 'true') {
          button.dataset.cooldownListener = 'true';

          button.addEventListener('click', (event) => {
            if (button.classList.contains('disabled')) {
              event.preventDefault(); // Zapobiegaj działaniu przycisku, jeśli jest na cooldownie
              return;
            }

            // Zapamiętaj oryginalny tekst przycisku
            const originalText = button.innerText;

            // Dodaj klasę disabled i ustaw cooldown na 3 sekundy
            button.classList.add('disabled');
            let countdown = 3.0;
            button.innerText = `${originalText} (${countdown.toFixed(1)}s)`;

            const interval = setInterval(() => {
              countdown -= 0.1;
              if (countdown <= 0) {
                clearInterval(interval);
                button.classList.remove('disabled');
                button.innerText = originalText;
              } else {
                button.innerText = `${originalText} (${countdown.toFixed(1)}s)`;
              }
            }, 100);
          });
        }
      });
    };

    // Wywołaj funkcję dodającą cooldown po załadowaniu komponentu
    addCooldownToButtons();
  }, []);
};

export default useButtonCooldown;
