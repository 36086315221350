import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import ikon z FontAwesome
import { faCircleXmark, faUser } from '@fortawesome/free-regular-svg-icons'; // Regularna wersja ikony
import { faCheck, faChevronDown, faTimes, faTriangleExclamation, faCaretDown, faAt, faFingerprint } from '@fortawesome/free-solid-svg-icons'; // Solidna wersja ikon
import { faFacebookF, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons'; // Brands wersja ikon

// Dodaj ikony do globalnej biblioteki FontAwesome
library.add(faCircleXmark, faCheck, faChevronDown, faTimes, faTriangleExclamation, faFacebookF, faInstagram, faTiktok, faYoutube, faUser, faCaretDown, faAt, faFingerprint);

// Eksportowanie komponentu FontAwesomeIcon
export default FontAwesomeIcon;
